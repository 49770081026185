<template>
  <div>
    <Form :options="formOptions"></Form>
  </div>
</template>
<script>
import moment from 'moment'
import Form from '../../../../src/components/form/Form.vue'
import axios from '@/components/axios'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  data () {
    return {
      formOptions: {
        gridForm: true,

        formTitle: `${
          this.$route.params.id ? 'Edit Ledger Voucher' : 'Add Ledger Voucher'
        }`,
        submitRouterPath: '/setup/ledger-voucher',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/ledgervoucher`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Company Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Date',
            name: 'nextfollowupdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter Type',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Contact Person',
            name: 'contactperson',
            value: '',
            type: 'text',
            placeholder: 'Enter Contact Person',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Mobile',
            name: 'mobile',
            value: '',
            type: 'number',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Email',
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Enter Mobile',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Address',
            name: 'address',
            value: '',
            type: 'text',
            placeholder: 'Enter Address',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Area',
            name: 'area',
            value: '',
            type: 'text',
            placeholder: 'Enter Area',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'State',
            name: 'state',
            value: '',
            type: 'text',
            placeholder: 'Enter state',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'City',
            name: 'city',
            value: '',
            type: 'text',
            placeholder: 'Enter city',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Pin Code',
            name: 'pincode',
            value: '',
            type: 'text',
            placeholder: 'Enter Pincode',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Remark',
            name: 'remarks',
            value: '',
            type: 'text',
            placeholder: 'Enter Remarks',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Assign to',
            name: 'assignto',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            dropdownname: 'assignto',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
          // {
          //   label: "Branch",
          //   name: "branch",
          //   value: "",
          //   type: "dropdown",
          //   class: "col-md-6",
          //   required: {
          //     required,
          //   },
          //   // inputStyle: "height: 25px; padding: 5px",
          //   options: [],
          // },
        ]
      }
    }
  },
  components: {
    Form
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },
  beforeMount () {
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  },
  mounted () {}
}
</script>
